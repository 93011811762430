import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import Navbar from '../components/_App/Navbar'
// import Team from '../components/Common/Team'
// import FunFactsArea from '../components/Common/FunFactsArea'
import Footer from '../components/_App/Footer'
import PageBanner from '../components/Common/PageBanner'
// import FeedbackStyleFour from '../components/Common/FeedbackStyleFour'
// import Partner from '../components/MachineLearning/Partner'

import AboutImg from '../assets/images/about4.png'

const About = () => (
  <Layout>
    <Seo title='About Us' />

    <Navbar />

    <PageBanner pageTitle='About Us' />

    <div className='about-area ptb-80'>
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-12'>
            <div className='ml-about-img'>
              <img src={AboutImg} alt='' />
            </div>
          </div>

          <div className='col-lg-6 col-md-12'>
            <div className='ml-about-content'>
              <span className='sub-title'>About Us</span>
              <h2>The GeoML Group</h2>
              <p>
                We are scientists, engineers, and students all enthusiastic
                about using Machine Learning and Data Science in geochemical
                studies of reactive transport on multiple scales, as well as in
                development of "Digital Twins" of relevant objects and
                phenomena.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='about-inner-area'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div className='about-text'>
                <h3>Our History</h3>
                <p>
                  We have more than 30 years of R&D experience in the field of
                  radioactive waste disposal, with expertise in chemical
                  thermodynamic databases, codes and modelling,
                  multiscale-multiphysics simulations, high performance
                  computing, and related software development.
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div className='about-text'>
                <h3>Our Mission</h3>
                <p>
                  We aim at making the methods and software behind the buzzwords
                  such as ML and AI really useful and used in geochemistry,
                  supported and provided by GeoML PaaS and SaaS. PaaS (Platform
                  as a Service) provides{' '}
                  <a
                    href='https://jupyter.org/hub'
                    target='_blank'
                    rel='noreferrer'
                  >
                    JupyterHub
                  </a>{' '}
                  servers; SaaS (Software as a Service) are (web) apps for
                  solving various user tasks in "no code" style.
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div className='about-text'>
                <h3>Who We Are</h3>
                <p>
                  The core of the Group is building around scientists working at
                  the{' '}
                  <a
                    href='https://www.psi.ch/en/les'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Laboratory for Waste Management
                  </a>{' '}
                  of the{' '}
                  <a
                    href='https://www.psi.ch/en'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Paul Scherrer Institut
                  </a>{' '}
                  <span title='Switzerland'>[CH]</span>, and at the affiliated
                  groups at{' '}
                  <a
                    title={
                      'Belgian Nuclear Research Centre' +
                      '\n' +
                      '[StudieCentrum voor Kernenergie (SCK)]' +
                      '\n' +
                      "[Centre d'étude de l'énergie Nucléaire (CEN)]"
                    }
                    href='https://www.sckcen.be/en'
                    target='_blank'
                    rel='noreferrer'
                  >
                    SCK CEN
                  </a>{' '}
                  <span title='Belgium'>[B]</span>,{' '}
                  <a
                    title={
                      'French Geological and Mining Research Bureau' +
                      '\n' +
                      '[Bureau de Recherches Géologiques et Minières (BRGM)]'
                    }
                    href='https://www.brgm.fr/en'
                    target='_blank'
                    rel='noreferrer'
                  >
                    BRGM
                  </a>{' '}
                  <span title='France'>[F]</span>; others involved in{' '}
                  <a
                    title='European Joint Programme on Radioactive Waste Management (EURAD)'
                    href='https://www.ejp-eurad.eu'
                    target='_blank'
                    rel='noreferrer'
                  >
                    EURAD
                  </a>{' '}
                  <span title='European Union'>[EU]</span> projects:{' '}
                  <a
                    title={
                      'Development and Improvement Of Numerical methods' +
                      '\n' +
                      'and Tools for modelling coupled processes (DONUT)'
                    }
                    href='https://www.ejp-eurad.eu/implementation/development-and-improvement-numerical-methods-and-tools-modelling-coupled-processes'
                    target='_blank'
                    rel='noreferrer'
                  >
                    DONUT
                  </a>
                  ,{' '}
                  <a
                    title='Assessment of Chemical Evolution of ILW and HLW Disposal Cells (ACED)'
                    href='https://www.ejp-eurad.eu/implementation/assessment-chemical-evolution-ilw-and-hlw-disposal-cells-aced'
                    target='_blank'
                    rel='noreferrer'
                  >
                    ACED
                  </a>
                  ,{' '}
                  <a
                    title='Fundamental Understanding of radionuclide retention (FUTURE)'
                    href='https://www.ejp-eurad.eu/implementation/fundamental-understanding-radionuclide-retention-future'
                    target='_blank'
                    rel='noreferrer'
                  >
                    FUTURE
                  </a>
                  ,{' '}
                  <a
                    title={
                      'MOnitoring equipment and DAta Treatment for' +
                      '\n' +
                      'Safe repository operation and staged closure (MODATS)'
                    }
                    href='https://www.ejp-eurad.eu/implementation/monitoring-equipment-and-data-treatment-safe-repository-operation-and-staged-closure'
                    target='_blank'
                    rel='noreferrer'
                  >
                    MODATS
                  </a>
                  ,{' '}
                  <a
                    title='Chemo-Mechanical AGIng of Cementitious materials (MAGIC)'
                    href='https://www.ejp-eurad.eu/implementation/chemo-mechanical-aging-cementitious-materials-magic'
                    target='_blank'
                    rel='noreferrer'
                  >
                    MAGIC
                  </a>
                  ; and{' '}
                  <a
                    title='Pre-disposal management of radioactive waste (PREDIS)'
                    href='https://predis-h2020.eu/predis-project'
                    target='_blank'
                    rel='noreferrer'
                  >
                    PREDIS
                  </a>{' '}
                  <span title='European Union'>[EU]</span> on related topics.
                </p>

                <p>
                  The GeoML servers are maintained by{' '}
                  <a
                    title={
                      '👋: info@congineer.com' +
                      '\n' +
                      '🌍: https://congineer.com' +
                      '\n' +
                      '🚀: The experts in modern full-stack web development!'
                    }
                    href='https://congineer.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    CONGINEER
                  </a>{' '}
                  <span title='Switzerland'>[CH]</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <Team /> */}

    {/* <FeedbackStyleFour /> */}

    {/* <FunFactsArea /> */}

    {/* <div className='pb-80'>
      <Partner />
    </div> */}

    <Footer />
  </Layout>
)

export default About
